<div class="container-fluid my-2">
  <h3 class="color-theme font-weight-bold mb-4">
    {{ "Anagrafica Fornitore" | translate }}
  </h3>
  <div class="my-4">
    <div *ngIf="!admin">
      <span>Email: </span> <span>{{ vendorData.email }}</span>
    </div>
    <div *ngIf="!admin">
      <span>URL Hech Romeo: </span> <span>{{ vendorData.urlhechromeo }}</span>
    </div>
  </div>

  <form *ngIf="admin" [formGroup]="emailForm">
    <div class="row mx-0 my-2">
      <div class="col-6 col-xl-4 form-group">
        <label for="lastName">Email</label>
        <input
          [(ngModel)]="vendorEmailTmp"
          [disabled]="!admin"
          type="email"
          class="form-control"
          id="email"
          name="email"
          formControlName="changemail"
          required
          (input)="emailForm.controls['changemail'].updateValueAndValidity()"
        />
        <ng-container
          *ngIf="
            emailForm.controls['changemail'].invalid &&
            (emailForm.controls['changemail'].dirty ||
              emailForm.controls['changemail'].touched)
          "
        >
          <app-small-text
            [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
          ></app-small-text>
        </ng-container>
      </div>

      <div class="col-6 col-xl-4">
        <button
          [disabled]="!admin"
          type="button"
          class="pl-4 pr-4 btn btn-primary info-button"
          (click)="changeVendorEmail()"
        >
          {{ "Salva" | translate }} Mail
        </button>
      </div>
    </div>
  </form>

  <div class="row mx-0">
    <div class="col-6 col-xl-4 form-group">
      <label for="url">URL Hech Romeo</label>
      <input
        [(ngModel)]="urlTmp"
        [disabled]="!admin"
        type="email"
        class="form-control"
        id="url"
      />
    </div>

    <div class="col-6 col-xl-4">
      <button
        [disabled]="!admin"
        type="button"
        class="pl-4 pr-4 btn btn-primary info-button"
        (click)="changeVendorUrl()"
      >
        {{ "Salva" | translate }} Url
      </button>
    </div>
  </div>

  <div class="row mx-0">
    <div class="col-4 col-xl-2 form-group">
      <label for="tvs">Licenze Tv</label>

      <input id="tvs" [disabled]="!admin" [(ngModel)]="tvManagement.authorizedtvs" type="number" min="0" class="form-control" />
    </div>

    <div class="col-4 col-xl-2 form-group">
      <label for="expiration">Scadenza</label>

      <input id="expiration" [disabled]="!admin" [(ngModel)]="tvManagement.expiration" type="date" class="form-control" />
    </div>

    <div class="col-6 col-xl-4">
      <button
        [disabled]="!admin"
        type="button"
        class="pl-4 pr-4 btn btn-primary info-button"
        (click)="updateVendorTvManagement()"
      >
        {{ "Salva" | translate }} Licenze
      </button>
    </div>
  </div>

  <div *ngIf="admin">
    <div class="container-fluid">
      <div class="row mx-0">
        <label>{{ "Configurazione Widget Tv" | translate }}*</label>
      </div>

      <div *ngIf="vendorData.code && vendorData.code.length > 0" class="row mx-0">
        <label>{{ "ID: " | translate }} {{vendorData.code}}</label>
      </div>

      <div class="row mx-0">
        <label>{{"Hai ROMEO?" | translate }}*</label>
      </div>

      <div class="container-fluid p-0">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="romeoRadios"
            id="romeoRadios1"
            [value]="true"
            [(ngModel)]="accountConfiguration.configuraton.romeo"
          />
          <label class="form-check-label options-label" for="romeoRadios1">
            {{ "Sì" | translate }}
          </label>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="romeoRadios"
            id="romeoRadios2"
            [value]="false"
            [(ngModel)]="accountConfiguration.configuraton.romeo"
          />
          <label class="form-check-label options-label" for="romeoRadios2">
            No
          </label>
        </div>
      </div>

      <div *ngIf="accountConfiguration.configuraton.romeo" class="row mx-0">
        <label>{{ "Hai server di caching?" | translate }}*</label>
      </div>

      <div *ngIf="accountConfiguration.configuraton.romeo" class="container-fluid p-0">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="cachingRadios"
            id="cachingRadios1"
            [value]="true"
            [(ngModel)]="accountConfiguration.configuraton.caching"
          />
          <label class="form-check-label options-label" for="cachingRadios1">
            {{ "Sì" | translate }}
          </label>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="cachingRadios"
            id="cachingRadios2"
            [value]="false"
            [(ngModel)]="accountConfiguration.configuraton.caching"
          />
          <label class="form-check-label options-label" for="cachingRadios2">
            No
          </label>
        </div>
      </div>

      <div *ngIf="accountConfiguration.configuraton.romeo && accountConfiguration.configuraton.caching === true" class="row mx-0">
        <div class="col-6 col-xl-4 pl-0">
          <label for="state">{{ "IP Caching" | translate }}*</label>

          <input
            type="text"
            class="form-control"
            id="urlCaching"
            name="urlCaching"
            [(ngModel)]="accountConfiguration.configuraton.urlcaching"
          />
        </div>
      </div>

      <div *ngIf="accountConfiguration.configuraton.romeo && accountConfiguration.configuraton.caching === false" class="row mx-0">
        <div class="col-6 col-xl-4 pl-0">
          <label for="state">{{ "Url Middleware" | translate }}*</label>

          <input
            type="text"
            class="form-control"
            id="urlMiddleware"
            name="urlMiddleware"
            [(ngModel)]="accountConfiguration.configuraton.urlmiddleware"
          />
        </div>
      </div>

      <div class="row mx-0 my-2">
        <button type="button" class="pl-4 pr-4 btn btn-primary" (click)="setAccountConfiguration()">
          {{ "Genera ID Account" | translate }}
        </button>
      </div>
    </div>
  </div>

  <form [formGroup]="registerForm">
    <div class="row mx-0 my-4">
      <div class="col-6 col-xl-4 mb-2">
        <div class="form-group">
          <label for="name">{{ "Nome" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="name"
            name="name"
            formControlName="referrername"
            required
            (input)="
              registerForm.controls['referrername'].updateValueAndValidity()
            "
          />
          <ng-container
            *ngIf="
              registerForm.controls['referrername'].invalid &&
              (registerForm.controls['referrername'].dirty ||
                registerForm.controls['referrername'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="lastName">{{ "Cognome" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="lastName"
            name="lastName"
            formControlName="referrerlastname"
            required
            (input)="
              registerForm.controls['referrerlastname'].updateValueAndValidity()
            "
          />
          <ng-container
            *ngIf="
              registerForm.controls['referrerlastname'].invalid &&
              (registerForm.controls['referrerlastname'].dirty ||
                registerForm.controls['referrerlastname'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="factoryname">{{ "Ragione Sociale" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="factoryname"
            name="factoryname"
            formControlName="factoryname"
            required
            (input)="
              registerForm.controls['factoryname'].updateValueAndValidity()
            "
          />
          <ng-container
            *ngIf="
              registerForm.controls['factoryname'].invalid &&
              (registerForm.controls['factoryname'].dirty ||
                registerForm.controls['factoryname'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="piva">{{ "P.IVA/CF" | translate }}</label>
          <input
            type="number"
            class="form-control"
            id="piva"
            name="piva"
            formControlName="piva"
            required
            (input)="registerForm.controls['piva'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['piva'].invalid &&
              (registerForm.controls['piva'].dirty ||
                registerForm.controls['piva'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="address">{{ "Indirizzo" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="address"
            name="address"
            formControlName="address"
            required
            (input)="registerForm.controls['address'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['address'].invalid &&
              (registerForm.controls['address'].dirty ||
                registerForm.controls['address'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="city">{{ "Città" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="city"
            name="city"
            formControlName="city"
            required
            (input)="registerForm.controls['city'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['city'].invalid &&
              (registerForm.controls['city'].dirty ||
                registerForm.controls['city'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>

        <div class="form-group">
          <label for="state">{{ "Regione" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="state"
            name="state"
            formControlName="state"
            required
            (input)="registerForm.controls['state'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['state'].invalid &&
              (registerForm.controls['state'].dirty ||
                registerForm.controls['state'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="zipcode">ZIP/CAP</label>
          <input
            type="text"
            class="form-control"
            id="zipcode"
            name="zipcode"
            formControlName="zipcode"
            required
            (input)="registerForm.controls['zipcode'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['zipcode'].invalid &&
              (registerForm.controls['zipcode'].dirty ||
                registerForm.controls['zipcode'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="country">{{ "Stato" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="country"
            name="country"
            formControlName="country"
            required
            (input)="registerForm.controls['country'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['country'].invalid &&
              (registerForm.controls['country'].dirty ||
                registerForm.controls['country'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>

        <div class="info-button-box">
          <button
            [disabled]="!admin"
            type="button"
            class="pl-4 pr-4 btn btn-primary"
            (click)="updateVendorData()"
          >
            {{ "Salva" | translate }}
          </button>
        </div>

      </div>

      <div class="col-6 col-xl-4 mb-2">

        <div class="form-group">
          <label for="banner">{{ "Insegna" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="banner"
            name="banner"
            formControlName="banner"
            required
            (input)="registerForm.controls['banner'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['banner'].invalid &&
              (registerForm.controls['banner'].dirty ||
                registerForm.controls['banner'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="billingcode">{{
            "Codice univoco d'ufficio" | translate
          }}</label>
          <input
            type="text"
            class="form-control"
            id="billingcode"
            name="billingcode"
            formControlName="billingcode"
            required
            (input)="
              registerForm.controls['billingcode'].updateValueAndValidity()
            "
          />
          <ng-container
            *ngIf="
              registerForm.controls['billingcode'].invalid &&
              (registerForm.controls['billingcode'].dirty ||
                registerForm.controls['billingcode'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="phone">{{ "Telefono" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="phone"
            name="phone"
            formControlName="phone"
            required
            (input)="registerForm.controls['phone'].updateValueAndValidity()"
          />
        </div>

        <div class="form-group">
          <label for="bannerbackgroundcolor">{{ "Colore Background" | translate }}</label>
          <input
            type="color"
            class="form-control w-25"
            id="bannerbackgroundcolor"
            name="bannerbackgroundcolor"
            formControlName="bannerbackgroundcolor"
            value="#ffffff"
            (input)="registerForm.controls['bannerbackgroundcolor'].updateValueAndValidity()"
          />
        </div>
        <div class="form-group">
          <label for="bannercolor">{{ "Colore testo titolo" | translate }}</label>
          <input
            type="color"
            class="form-control w-25"
            id="bannercolor"
            name="bannercolor"
            formControlName="bannercolor"
            value="#e66465"
            (input)="registerForm.controls['bannercolor'].updateValueAndValidity()"
          />
        </div>
        <div>
          <div class="mb-2">
            <img
            class="mediaobject-img"
            *ngIf="mediaobject"
              [src]="'data:image/png;base64,' + mediaobject"
              alt=""
            />
          </div>

          <div class="mb-3" style="display: flex; flex-direction: row">
            <input
              #image
              type="file"
              accept="image/png, image/jpeg"
              (change)="encodeImageFileAsURL( $event.target.files )"
            />
            <!-- [disabled]="!tvSettings.welcomeproject" -->
            <button
              type="button"
              (click)="deleteImage(image)"
              class="btn btn-danger ml-2"
            >
              {{ "Elimina" | translate }}
            </button>
          </div>
        </div>

      </div>
    </div>
  </form>
</div>
